import { ColumnType } from "antd/lib/table";
import _ from "lodash";
import React, { PureComponent } from "react";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { ReactSystemFunctions } from "../../../../shared/utilty/react-system-functions";
import { CommonProps } from "../../common/common-props";

export interface TableDataProps extends ColumnType<any> {
  rowData?: any;
  children?: React.ReactNode;
  isEditableColumn?: boolean;
  isEditMode?: boolean;
}
declare let window: any;
interface KTableDataState {}

class TableData<P, S> extends PureComponent<TableDataProps & P & CommonProps, KTableDataState & S> {
  constructor(props: TableDataProps & P & CommonProps) {
    super(props);
  }

  static setDataFieldValues = (
    children: any,
    rowData?: any,
    rowIndex?: number,
    isEditableColumn?: boolean,
    isEditMode?: boolean
  ): React.ReactNode[] => {
    const result: React.ReactNode[] = [];
    children &&
      React.Children.map(children, (child: any, childIndex) => {
        const props = {} as any;
        let manupulatedChildren: React.ReactNode[] = [];
        const dataFieldKey = new RegExp(/\[datafield:([a-zA-Z0-9-_]+)\]/);
        const oldValue = new RegExp(/\[datafield:([a-zA-Z0-9-_]+)\]/g);
        if (child && child?.props) {
          let { visExField1 } = child.props;
          if (visExField1) {
            const matchResult = child.props.visExField1.toString().match(dataFieldKey);
            if (matchResult) {
              const dataField = matchResult[1];
              const newValue = rowData[dataField] ?? "";
              visExField1 = visExField1.replace(oldValue, newValue);
            }
          }
          let { visExField2 } = child.props;
          if (visExField2) {
            const matchResult = child.props.visExField2.toString().match(dataFieldKey);
            if (matchResult) {
              const dataField = matchResult[1];
              const newValue = rowData[dataField] ?? "";
              visExField2 = visExField2.replace(oldValue, newValue);
            }
          }
          const { visExRule } = child.props;
          if (!_.isUndefined(visExField1) && !_.isUndefined(visExField2) && !_.isUndefined(visExRule)) {
            if (child?.props?.visibilityByAuthorization === "hidden") {
              props.visibility = "hidden";
            } else {
              props.visibility =
                ReactSystemFunctions[visExRule](visExField1, visExField2) === true ? "visible" : "hidden";
            }
          }

          Object?.keys(child.props)?.forEach((key) => {
            if (typeof child.props[key] === "string") {
              if (child.props[key]?.toString().indexOf("datafield:") > -1) {
                const matchResult = child.props[key]?.toString().match(dataFieldKey);
                if (rowData && matchResult && matchResult?.length > 0) {
                  const dataField = matchResult[1];
                  let newValue = rowData[dataField];
                  if (newValue === undefined) newValue = "";
                  props[key] = child.props[key]?.toString().replace(oldValue, newValue);
                  if (
                    !window ||
                    !window.kuika ||
                    (window.kuika?.dashboardState !== 1 && window.kuika?.dashboardState !== 17)
                  ) {
                    if (props[key] == "" || props[key] == "null" || props[key] == "undefined") {
                      props[key] = " ";
                    }
                  }
                }
              }
              if (key === "children") {
                manupulatedChildren.push(props.children);
              }
            } else if (key === "children") {
              manupulatedChildren = TableData.setDataFieldValues(child.props.children, rowData, rowIndex);
            }
            if (!props[key]) props[key] = child.props[key];
          });
        }
        props.rowIndex = rowIndex;
        if (manupulatedChildren?.length > 0) {
          result.push(React.cloneElement(child, props, manupulatedChildren));
        } else {
          result.push(React.cloneElement(child, props, child.props.children));
        }
      });
    return result;
  };

  render = () => {
    if (this.props.visibility === "hidden") return <></>;
    const { rowData } = this.props;
    const { rowIndex } = this.props;
    const { isEditableColumn, isEditMode } = this.props;
    return <>{TableData.setDataFieldValues(this.props.children, rowData, rowIndex, isEditableColumn, isEditMode)}</>;
  };
}

const tableData = withCommonEvents<TableDataProps & CommonProps, KTableDataState>(TableData);
export { tableData as TableData };
