import withCommonEvents from "../../../shared/hoc/with-common-events";
import { ProgressBase, ProgressBaseProps } from "./base/progress-base";

class ProgressBar extends ProgressBase<any, any> {
  constructor(props: ProgressBaseProps) {
    super(props);
  }

  public static defaultProps = {};
}

const progressBar = withCommonEvents(ProgressBar);
export { progressBar as ProgressBar };
