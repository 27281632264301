import React from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

declare let window: Window & { kuika: any };

interface ChartLabelProps {}

interface ChartLabelState {}

class ChartLabel extends React.PureComponent<ChartLabelProps & CommonProps, ChartLabelState> {
  constructor(props: ChartLabelProps) {
    super(props);
    this.state = {};
  }

  getProps(): ChartLabelProps {
    const props: ChartLabelProps = { ...this.props };
    return props;
  }
}

const chartLabel = withCommonEvents(ChartLabel);
export { chartLabel as ChartLabel };
