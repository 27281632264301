import { Menu } from "antd";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common/common-props";

interface MenuItemProps {}

interface MenuItemState {}

class MenuItem extends PureComponent<MenuItemProps & CommonProps, MenuItemState> {
  constructor(props: MenuItemProps) {
    super(props);
    this.state = {};
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props.icon) {
      props.icon = <span className="material-icons md-24">{props.icon}</span>;
    }
    if (props.label) {
      props.children = props.label;
    }
    if (props.link) {
      props.children = (
        <a href={props.link} target="_self">
          {props.label}
        </a>
      );
      delete props.label;
    }
    props.key = props.key ?? props.id ?? Guid.create().toString();
    return props;
  };

  render(): ReactNode {
    return <Menu.Item {...this.getProps()}>{this.getProps().children}</Menu.Item>;
  }
}

const menuItem = withCommonEvents(MenuItem);
export { menuItem as MenuItem };
