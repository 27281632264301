import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import { ViewMode } from "../../base-table";

interface TableBodyCellProps {
  calculatedCellBgColor?: (rowIndex?: number, rowData?: any) => string;
  calculatedCellFontColor?: (rowIndex?: number, rowData?: any) => string;
  style?: any;
  rowData?: any;
  rowIndex?: number;
  columnIndex: number;
  title: string;
  mode?: ViewMode;
  onCellClick: () => void;
  isEditable?: boolean;
  isEditMode?: boolean;
}
interface TableBodyCellState {}

export class TableBodyCell extends PureComponent<TableBodyCellProps, TableBodyCellState> {
  constructor(props: TableBodyCellProps) {
    super(props);
  }

  getStyle = (): React.CSSProperties => {
    let style: React.CSSProperties = {};
    if (this.props.style) {
      style = _.clone(this.props.style);
    }
    if (this.props.calculatedCellBgColor) {
      style.background = this.props.calculatedCellBgColor(this.props.rowIndex, this.props.rowData);
    }
    if (this.props.calculatedCellFontColor) {
      style.color = this.props.calculatedCellFontColor(this.props.rowIndex, this.props.rowData);
    }
    if (
      !style.border &&
      !style.borderColor &&
      !style.borderTop &&
      !style.borderBottom &&
      !style.borderRight &&
      !style.borderLeft &&
      !style.borderWidth
    ) {
      style.borderWidth = 0;
    }
    if (style?.display) {
      delete style.display;
    }
    if (!style?.padding) {
      style.padding = "0px";
    }
    if (style.padding && (style?.paddingLeft || style?.paddingRight || style?.paddingTop || style?.paddingBottom)) {
      delete style.padding;
    }
    return style;
  };

  handleCellClick = (e: any) => {
    if (this.props.onCellClick) {
      this.props.onCellClick();
    }
  };

  filterNodeByKuikaComponentName(node) {
    if (!node || !node.props) {
      return true;
    }
    const { props } = node;

    if (props && props.kuikacomponentname != "Label") {
      return node;
    }

    // If the node has children, recursively filter them
    if (props && props.children) {
      const filteredChildren = React.Children.map(props.children, (child) =>
        this.filterNodeByKuikaComponentName(child)
      );

      const hasFilteredChildren = filteredChildren.some((child) => child !== null);

      if (hasFilteredChildren) {
        return {
          ...node,
          props: {
            ...props,
            children: filteredChildren
          }
        };
      }
    }

    return null;
  }

  render(): ReactNode {
    return <td onClickCapture={this.handleCellClick} {...this.props} style={this.getStyle()} />;
  }
}
