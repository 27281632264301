import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { BaseChart, BaseChartProps } from "../base-chart";

class DonutChart extends BaseChart<any, any> {
  constructor(props: BaseChartProps) {
    super(props);
  }

  public static defaultProps = {
    mode: "doughnut"
  };
}

const donutChart = withCommonEvents(DonutChart);
export { donutChart as DonutChart };
