import AntdModal, { ModalProps as AntdModalProps } from "antd/lib/modal/Modal";
import { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common/common-props";

interface ModalProps extends AntdModalProps {
  height: string;
}

interface ModalState {}

class Modal extends PureComponent<ModalProps & CommonProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    let height = "70vh";
    if (this.props.height) {
      height = this.props.height;
    }

    return (
      <>
        <AntdModal
          bodyStyle={{ height, overflow: height ? "hidden" : "auto" }}
          {...this.props}
          destroyOnClose={true}
          maskClosable={this.props.closable}
          keyboard={this.props.closable}
        ></AntdModal>
      </>
    );
  }
}

const modal = withCommonEvents(Modal);
export { modal as Modal };
