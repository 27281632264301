import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

declare let window: any;
interface LayoutHeaderProps {
  style?: React.CSSProperties;
}

interface LayoutHeaderState {}

class LayoutHeader extends PureComponent<LayoutHeaderProps & CommonProps, LayoutHeaderState> {
  constructor(props: LayoutHeaderProps) {
    super(props);
    this.state = {};
  }

  getStyleProp = () => {
    let style: any = {};

    if (this.props.style) {
      style = _.clone(this.props.style);
    }

    if (!this.props.children) style.minHeight = "100px";

    style.overflow = "hidden";
    return style;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props.style) {
      delete props.style;
    }
    return props;
  };

  render(): ReactNode {
    return (
      <>
        <div {...this.getProps()} style={{ ...this.getStyleProp() }}>
          {this.props.children}
        </div>
      </>
    );
  }
}

const layoutHeader = withCommonEvents(LayoutHeader);
export { layoutHeader as LayoutHeader };
