import { Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { ReactNode } from "react";
import { Doughnut } from "react-chartjs-2";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { CommonProps } from "../../common-props";
import { GradientDoughnut } from "./gradient-doughnut";

declare let window: Window & { kuika: any };

ChartJS.register(...registerables);

interface RadialChartProps {
  dataSource: any[];
  style?: React.CSSProperties;
  dataslicetextfield?: string;
  dataslicepointfield?: string;
  dataslicepercentagefield?: string;
  dataslicelabelfield?: string;
  datalevelpercentagefield?: string;
  datalevelcolorfield?: string;
  dataleveltextfield?: string;
  chartText?: string;
  options?: any[];
  levels?: any[];
  lineColor?: string;
  radialLineColor?: string;
  lineWidth?: number;
  backgroundColor?: string;
  chartTextColor?: string;
  legendTextColor?: string;
}

interface RadialChartState {}

class RadialChart extends React.PureComponent<RadialChartProps & CommonProps, RadialChartState> {
  private static readonly slicesDashboardData = [
    {
      label: "Slice 1",
      text: "First Slice",
      percent: 40,
      point: 100
    },
    {
      label: "Slice 2",
      text: "Second Slice",
      percent: 25,
      point: 30
    },
    {
      label: "Slice 3",
      text: "Third Slice",
      percent: 15,
      point: 50
    },
    {
      label: "Slice 3",
      text: "Third Slice",
      percent: 15,
      point: 60
    },
    {
      label: "Slice 3",
      text: "Third Slice",
      percent: 15,
      point: 70
    },
    {
      label: "Slice 3",
      text: "Third Slice",
      percent: 15,
      point: 80
    }
  ];

  private static readonly levelsDashboardData = [
    { levelPercent: 10, color: "#A6341F", text: "Level 1" },
    { levelPercent: 20, color: "#BD4538", text: "Level 2" },
    { levelPercent: 30, color: "#D08346", text: "Level 3" },
    { levelPercent: 40, color: "#E4BF59", text: "Level 4" },
    { levelPercent: 50, color: "#FBF461", text: "Level 5" },
    { levelPercent: 60, color: "#EEFF94", text: "Level 6" },
    { levelPercent: 70, color: "#B8CD5F", text: "Level 7" },
    { levelPercent: 80, color: "#76AD5B", text: "Level 8" },
    { levelPercent: 90, color: "#488450", text: "Level 9" },
    { levelPercent: 100, color: "#33603C", text: "Level 10" }
  ];

  private canvasId: string = `radial-chart-${Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))}`;

  private calculateAngle(chart, x1, y1, x2, y2): any {
    const aX = x1 - chart.width / 2;
    const aY = y1 - chart.height / 2;
    const bX = x2 - chart.width / 2;
    const bY = y2 - chart.height / 2;

    const angleRadians = Math.atan2(aY, aX) - Math.atan2(bY, bX);

    const angleDegrees = (angleRadians * 180) / Math.PI;

    return angleDegrees;
  }

  private createPartTextsFunc(chart) {
    if (chart.getDatasetMeta(1)) {
      const slices = RadialChart.convertOptionsToSlicesData(
        this.props.options ?? [],
        this.props.dataslicelabelfield?.toLowerCase(),
        this.props.dataslicetextfield?.toLowerCase(),
        this.props.dataslicepercentagefield?.toLowerCase(),
        this.props.dataslicepointfield?.toLowerCase()
      );

      var halfwidth = chart.width / 2;
      var halfheight = chart.height / 2;

      chart.getDatasetMeta(0).data.forEach((datapoint, i) => {
        const { x, y } = datapoint.tooltipPosition();

        let textPosX =
          x >= halfwidth
            ? x + (datapoint.outerRadius - datapoint.innerRadius) / 1.5 + 70
            : x - (datapoint.outerRadius - datapoint.innerRadius) / 1.5 - 70;
        let textPosY =
          y >= halfheight
            ? y + (datapoint.outerRadius - datapoint.innerRadius) / 1.5 + 70
            : y - (datapoint.outerRadius - datapoint.innerRadius) / 1.5 - 70;

        const angleDegrees = this.calculateAngle(chart, x, y, textPosX, textPosY);

        textPosX =
          (textPosX - chart.width / 2) * Math.cos((angleDegrees * Math.PI) / 180) -
          (textPosY - chart.height / 2) * Math.sin((angleDegrees * Math.PI) / 180) +
          chart.width / 2;
        textPosY =
          (textPosX - chart.width / 2) * Math.sin((angleDegrees * Math.PI) / 180) +
          (textPosY - chart.height / 2) * Math.cos((angleDegrees * Math.PI) / 180) +
          chart.height / 2;

        const ctxText = chart.ctx;

        ctxText.beginPath();
        ctxText.save();

        ctxText.textAlign = "center";
        ctxText.translate(textPosX, textPosY);
        ctxText.font = `${chart.width * 0.019}px Arial`;
        ctxText.textBaseline = "middle";
        ctxText.fillStyle = this.props.legendTextColor ?? "black";
        ctxText.fillText(`${slices[i]?.text}`, 0, 0);

        ctxText.restore();
      });
    }
  }

  componentDidMount(): void {
    this.setDynamicStyle();
    this.handleComponentStyling();
  }

  componentDidUpdate(): void {
    this.setDynamicStyle();
  }

  handleComponentStyling = () => {
    const isDesignTime = window.kuika?.isDesignTime;
    const node = ReactDOM.findDOMNode(this) as Element;
    const parent = node.parentElement;
    if (parent && parent.style && isDesignTime) {
      if (this.props.style?.display === "inline" || !this.props.style?.display) parent.style.display = "inline-block";
      else parent.style.display = this.props.style?.display;
    }
  };

  getHeight(): string | undefined {
    if (this.props.style && this.props.style?.height) {
      return this.props.style.height.toString();
    }

    return "450px";
  }

  getMinHeight(): string | undefined {
    if (this.props.style && this.props.style?.minHeight) {
      return this.props.style.minHeight.toString();
    }
    return undefined;
  }

  getMaxHeight(): string | undefined {
    if (this.props.style && this.props.style?.maxHeight) {
      return this.props.style.maxHeight.toString();
    }

    return undefined;
  }

  getWidth(): string | undefined {
    if (this.props.style && this.props.style?.width) {
      return "auto";
    }

    return "450px";
  }

  getMinWidth(): string | undefined {
    if (this.props.style && this.props.style?.minWidth) {
      return this.props.style.minWidth.toString();
    }

    return undefined;
  }

  getMaxWidth(): string | undefined {
    if (this.props.style && this.props.style?.maxWidth) {
      return this.props.style.maxWidth.toString();
    }

    return undefined;
  }

  getDisplay(): string | undefined {
    if (this.props.style && this.props.style?.display) {
      if (this.props.style?.display === "inline") return "inline-block";
      return this.props.style.display.toString();
    }

    return "inline-block";
  }

  getNumbersOfStyleProperties = (val: any) => {
    let numb: any = val?.match(/\d/g);
    numb = numb?.join("");
    return parseInt(numb);
  };

  getDynamicCss = (): string => {
    const className: string = this.canvasId;
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";

    const { width, minWidth, maxWidth, height } = this.props.style;
    if ((width && !maxWidth && !minWidth) || minWidth < width || maxWidth > width) {
      const reelWidth = this.getNumbersOfStyleProperties(width) + 100;
      const reelHeight = this.getNumbersOfStyleProperties(height) - 50;
      result += `.${className} {
          min-width: ${reelWidth}px !important;
        }`;
      result += `.${className} {
          max-height: ${reelHeight}px !important;
        }`;
    }
    return result;
  };

  setDynamicStyle = () => {
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(this.canvasId) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${generatedCss}`;
    }
  };

  partTexts = {
    id: "partTexts",
    beforeDraw: (chart) => {
      this.createPartTextsFunc(chart);
    }
  };

  constructor(props: RadialChartProps) {
    super(props);
    this.state = {};
  }

  getProps(): RadialChartProps {
    const props: RadialChartProps = { ...this.props };
    return props;
  }

  static lowercaseKeys(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = obj[key];
      return accumulator;
    }, {});
  }

  static convertOptionsToSlicesData = (options, label, text, percentage, point) => {
    const source = window.kuika?.isDesignTime ? RadialChart.slicesDashboardData : options ?? [];
    if (window.kuika?.isDesignTime) return source;
    const items = source?.map((item) => {
      let optionLowercase = RadialChart.lowercaseKeys(item);
      if (optionLowercase[percentage] != null && optionLowercase[percentage]) {
        return {
          label: optionLowercase[label],
          text: optionLowercase[text],
          percent: optionLowercase[percentage],
          point: optionLowercase[point] != null ? optionLowercase[point] : 0
        };
      }
    });
    return items.filter(function (item) {
      if (item) return true;
      return false;
    });
  };

  static convertLevelOptionsToLevelsData = (levels, perc, color, text) => {
    const source = window.kuika?.isDesignTime ? RadialChart.levelsDashboardData : levels ?? [];
    if (window.kuika?.isDesignTime) return source;
    const items = source?.map((item) => {
      let optionLowercase = this.lowercaseKeys(item);
      return {
        levelPercent: optionLowercase[perc],
        color: optionLowercase[color],
        text: optionLowercase[text]
      };
    });
    items?.sort((a, b) => {
      return b.levelPercent - a.levelPercent;
    });
    return items.filter(function (item) {
      if (item.levelPercent != null && item.color != null) return true;
      return false;
    });
  };

  render(): ReactNode {
    const slices = RadialChart.convertOptionsToSlicesData(
      this.props.options ?? [],
      this.props.dataslicelabelfield?.toLowerCase(),
      this.props.dataslicetextfield?.toLowerCase(),
      this.props.dataslicepercentagefield?.toLowerCase(),
      this.props.dataslicepointfield?.toLowerCase()
    );

    const levels = RadialChart.convertLevelOptionsToLevelsData(
      this.props.levels ?? [],
      this.props.datalevelpercentagefield?.toLowerCase(),
      this.props.datalevelcolorfield?.toLowerCase(),
      this.props.dataleveltextfield?.toLowerCase()
    );

    const slicePercents = slices?.map((slice, index) => {
      if (slice) return slice.percent;
    });
    const mappedLevels = levels
      ?.filter(function (level) {
        if (level.levelPercent > 100) {
          if (!slices.find((s) => s.point > 100)) return false;
        }
        if (level.levelPercent != null && level.color != null) return true;

        return false;
      })
      .map((level, index) => {
        return {
          data: slicePercents,
          backgroundColor: (context) => {
            return this.props.backgroundColor ?? "#EBEBEB";
          },
          sliceText: null,
          dataLabels: slicePercents.map((data: any, index: any) => {
            return `${Math.floor(data / 10) * 10}%`;
          }),
          datalabels: {
            display(ctx) {
              return slices[ctx.dataIndex].point > 100;
            },
            color: "#EBEBEB",
            formatter(val, ctx) {
              return ctx.dataset.sliceText;
            },
            rotation(ctx) {
              const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce((a, b) => a + b, 0);
              const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
              const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum) * 360;
              return rotation;
            }
          }
        };
      });
    const data = {
      datasets: mappedLevels
    };

    const options: any = {
      cutout: "20%",
      hover: { mode: null },
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding(ctx) {
          return ctx.chart.width * 0.12;
        }
      }
    };

    const plugins: any = [this.partTexts, ChartDataLabels];
    const gradientDougnet = <GradientDoughnut {...this.props} />;
    return (
      <div
        className={this.canvasId}
        style={{
          position: "relative",
          height: this.getHeight(),
          minHeight: this.getMinHeight(),
          maxHeight: this.getMaxHeight(),
          width: this.getWidth(),
          minWidth: this.getMinWidth(),
          // maxWidth: this.getMaxWidth(),
          display: this.getDisplay()
        }}
      >
        <Doughnut
          id={this.canvasId}
          {...this.getProps()}
          options={options}
          className={this.canvasId}
          data={data}
          plugins={plugins}
          style={{
            height: "unset",
            width: this.getWidth(),
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            position: "absolute",
            top: 0,
            left: 0
          }}
        />
        {gradientDougnet}
      </div>
    );
  }
}

const radialChart = withCommonEvents(RadialChart);
export { radialChart as RadialChart };
