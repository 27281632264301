import { Layout, Menu } from "antd";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";

const { Sider } = Layout;
declare let window: any;
interface SiderMenuProps {
  selectedKey?: any;
  onChange?: (value: any) => any;
  mode?: "inline" | "vertical" | "horizontal";
  theme?: "light" | "dark";
  options?: any[];
  primaryColor?: string;
  collapsedButtonColor?: string;
  className?: string;
  style?: any;
  collapsed?: boolean;
  value?: any;
  dataidfield?: string;
  datatitlefield?: string;
  dataparentidfield?: string;
  datalinkfield?: string;
  dataiconfield?: string;
  dataorderfield?: string;
  collapsible: boolean;
}

interface SiderMenuState {
  collapsed: boolean;
  selectedKey: string;
  uniqueKey: Guid;
}

class SiderMenu extends PureComponent<SiderMenuProps, SiderMenuState> {
  private memoizedDynamicCssResult = "";

  private dataIdField?: string;

  private dataTitleField?: string;

  private dataParentIdField?: string;

  private dataLinkField?: string;

  private dataIconField?: string;

  private dataOrderField?: string;

  constructor(props: SiderMenuProps) {
    super(props);
    this.state = {
      collapsed: props.value?.collapsed ?? false,
      selectedKey: props.value?.selectedKey ?? "",
      uniqueKey: Guid.create()
    };
    this.initializeFieldKeys();
  }

  initializeFieldKeys = (): void => {
    if (!window.kuika?.isDesignTime) {
      this.dataIdField = this.props.dataidfield?.toLowerCase();
      this.dataTitleField = this.props.datatitlefield?.toLowerCase();
      this.dataParentIdField = this.props.dataparentidfield?.toLowerCase();
      this.dataLinkField = this.props.datalinkfield?.toLowerCase();
      this.dataIconField = this.props.dataiconfield?.toLowerCase();
      this.dataOrderField = this.props.dataorderfield?.toLowerCase();
    }
  };

  handleComponentStyling = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const menuTitleColor = this.props.primaryColor;
    const parentNode = node.parentNode as Element;

    let grandParentNode: Element = null;
    let triggerCollapsePanel: any = null;
    if (parentNode) {
      (parentNode as any).style.display = "inline-block";
      grandParentNode = parentNode.parentNode as Element;
      if (
        window.kuika?.isDesignTime &&
        grandParentNode &&
        grandParentNode.children &&
        grandParentNode.children.length > 1
      ) {
        triggerCollapsePanel = grandParentNode.children[1];
      } else if (
        grandParentNode &&
        grandParentNode.children &&
        grandParentNode.children.length > 0 &&
        grandParentNode.children[0].children &&
        grandParentNode.children[0].children.length > 0 &&
        grandParentNode.children[0].children[0].children &&
        grandParentNode.children[0].children[0].children.length > 1
      ) {
        triggerCollapsePanel = grandParentNode.children[0].children[0].children[1];
      }
      if (triggerCollapsePanel && triggerCollapsePanel.style) {
        const svg = triggerCollapsePanel.children[0]?.children[0] as HTMLElement;
        if (svg && svg.style) {
          svg.style.fill = "#fff";
        }
      }
    }
  };

  componentDidMount() {
    this.setDynamicStyle();
    this.handleComponentStyling();

    const storedLastVisitedMenuId = localStorage.getItem("lastVisitedMenuId");
    const storedIsMenuCollapsed = localStorage.getItem("isMenuCollapsed");

    if (!this.props.selectedKey && !this.state.selectedKey && storedLastVisitedMenuId) {
      this.handleSelectMenuItem({ key: storedLastVisitedMenuId });
    }

    if (!this.props.collapsed && !this.state.collapsed) {
      if (storedIsMenuCollapsed === "true") {
        this.handleCollapseChange(true);
      } else {
        this.handleCollapseChange(false);
      }
    }

    const handleResize = () => {
      const isMobileOrTablet = window.innerWidth <= 768;
      if (isMobileOrTablet && !window.kuika?.isDesignTime) {
        this.handleCollapseChange(true);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }

  componentDidUpdate() {
    this.handleComponentStyling();
    this.setDynamicStyle();
  }

  static getDerivedStateFromProps(nextProps: SiderMenuProps) {
    if (window.kuika?.isDesignTime) {
      return {
        collapsed: nextProps.collapsed !== undefined ? nextProps.collapsed : false,
        selectedKey: nextProps.selectedKey ?? ""
      } as SiderMenuState;
    }
    const result = {} as SiderMenuState;
    result.collapsed = nextProps.value?.collapsed;
    result.selectedKey = nextProps.value?.selectedKey;
    return result;
  }

  getClassName = () => {
    let result = "";
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} slider-menu_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    const menuTitleColor = this.props.primaryColor;
    result += `.${className.trim()} .ant-menu-title-content {
      text-align: left;
    }`;
    if (menuTitleColor) {
      result += `.${className.trim()} > .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-title-content, .${className.trim()} > .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-item-icon, .${className.trim()} > .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
        color: ${menuTitleColor} !important;
        transition: none !important;
      }`;
      result += `.${className.trim()} .ant-menu-item-selected {
        background: ${this.setOpacity(menuTitleColor, 0.2)};
      }`;

      result += `.${className.trim()} > .ant-menu-item-selected > .ant-menu-inline-collapsed-noicon {
        color: ${menuTitleColor} !important;
      }`;

      result += `.${className.trim()} .ant-menu-item-selected {
        color: ${menuTitleColor} !important;
      }`;
      result += `.${className.trim()} .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-item-icon, .${className.trim()} .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-title-content, .${className.trim()} .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow  {
        color: ${menuTitleColor} !important;
      }`;

      // set parent of
      // result += `.${className.trim()} > .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-title-content {
      //   color: ${menuTitleColor} !important;
      // }`;
    }
    return result;
  };

  setDynamicStyle = () => {
    const uniquekey: string = this.state.uniqueKey.toString();
    const isDesignTime = window.kuika?.isDesignTime;
    if (!uniquekey) {
      return;
    }
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }

    const node = ReactDOM.findDOMNode(this) as any;
    const siders = node.querySelectorAll(".ant-layout-sider-children");
    if (siders && siders.length > 0 && isDesignTime) {
      const sider = siders[0] as HTMLElement;
      const siderHeight = sider.offsetHeight + 29;
      (sider.children[1] as any).style.top = `${siderHeight}px`;
      const siderTrigger = node.querySelector(".ant-layout-sider-trigger") as HTMLElement;
      if (siderTrigger) {
        siderTrigger.style.display = "none";
      }
    }
    if (this.props.mode === "horizontal") {
      const siders = node.querySelectorAll(".ant-layout-sider");
      if (siders && siders.length > 0) {
        siders.forEach((s: any) => {
          s.style.width = "100%";
          s.style.maxWidth = "100%";
          s.style.minWidth = "100%";
        });
      }
      node.style.width = "100%";
      node.style.maxWidth = "100%";
      node.style.minWidth = "100%";
      const inlineSubmenus = node.querySelectorAll(".ant-menu-submenu-inline");
      if (inlineSubmenus && inlineSubmenus.length > 0) {
        inlineSubmenus.forEach((s: any) => {
          s.style.display = "inline-block";
          if (s.children && s.children.length > 0) {
          }
        });
      }
      if (node.parentNode && node.parentNode.style) {
        node.parentNode.style.width = "100%";
        node.parentNode.style.display = "block";
      }
      const sider = node.querySelector(".ant-layout-sider");
      if (sider && sider.style) {
        sider.style.display = "block";
        sider.style.width = "100%";
      }
      const siderChildren = node.querySelector(".ant-layout-sider-children");
      if (siderChildren && siderChildren.style) {
        siderChildren.style.width = "100%";
        siderChildren.style.display = "block";
      }

      const antMenu = node.querySelector(".ant-menu");
      if (antMenu) {
        antMenu.style.display = "flex";
      }
      const antMenuItems = node.querySelectorAll(".ant-menu-item");
      if (antMenuItems && antMenuItems.length > 0) {
        antMenuItems.forEach((item: any) => {
          item.style.minHeight = "48px";
        });
      }
    }
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    if (!props.mode || props.mode === "vertical") {
      props.mode = "inline";
    }
    if (props.mode === "horizontal") {
      props.overflowedIndicator = false;
      props.disabledOverflow = true;
    }
    return props;
  };

  setOpacity = (color: string, opacity: number | any) => {
    if (!color) return color;
    color = color.replace(/[\srgba()]/g, "");
    const rgbaValues = color.split(",");
    opacity = parseFloat(opacity);
    opacity = Math.min(Math.max(opacity, 0), 1);
    rgbaValues[3] = opacity.toString();
    const updatedColor = `rgba(${rgbaValues.join(",")})`;

    return updatedColor;
  };

  isColorCloserToWhiteOrBlack = (rgbaColor: string) => {
    const rgbaRegex: RegExp = /rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)/;
    const match: RegExpMatchArray = rgbaColor.match(rgbaRegex);

    if (!match) {
      return true;
    }

    const [, r, g, b] = match.map(Number);

    const luminance: number = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luminance > 128;
  };

  handleSelectMenuItem = ({ key }) => {
    if (this.props.onChange) {
      this.props.onChange({
        selectedKey: key,
        collapsed: this.state.collapsed
      });
    }
    localStorage.setItem("lastVisitedMenuId", key);
  };

  handleCollapseChange = (collapsed: boolean) => {
    if (this.props.onChange) {
      this.props.onChange({
        selectedKey: this.state.selectedKey,
        collapsed
      });
    }
    localStorage.setItem("isMenuCollapsed", collapsed.toString());
  };

  setSelectedKey = (key: string) => {
    this.handleSelectMenuItem({ key });
  };

  getSelectedKey = () => {
    if (this.props.selectedKey) {
      return this.props.selectedKey;
    }
    if (this.state.selectedKey) {
      return this.state.selectedKey;
    }
    return localStorage.getItem("lastVisitedMenuId");
  };

  getParentOfSelectedKey = () => {
    let parentKey = "";
    const key = this.getSelectedKey();
    const items = this.getProps().options;
    if (items) {
      items.forEach((item) => {
        if (item.children) {
          item.children.forEach((child) => {
            if (child.key === key) {
              parentKey = item.key;
            }
          });
        }
      });
    }
    if (parentKey) {
      return parentKey;
    }
    return key;
  };

  getItems = () => {
    if (this.props.options && this.props.options?.length > 0) {
      return this.convertOptionsToItemData(this.props.options);
    }
    if ((!this.props.options || this.props.options?.length === 0) && this.props.dataidfield) {
      return [];
    }
  };

  lowercaseKeys(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = obj[key];
      return accumulator;
    }, {});
  }

  convertOptionsToItemData = (options: any[]): any[] => {
    const itemMap = {};

    options?.forEach((option) => {
      let optionLowercase = this.lowercaseKeys(option);
      itemMap[optionLowercase[this.dataIdField]] = {
        key: optionLowercase[this.dataIdField],
        label: optionLowercase[this.dataLinkField] ? (
          <a href={optionLowercase[this.dataLinkField]}>{optionLowercase[this.dataTitleField]}</a>
        ) : (
          optionLowercase[this.dataTitleField]
        ),
        icon: <span className="material-icons md-24">{optionLowercase[this.dataIconField]}</span>,
        order: optionLowercase[this.dataOrderField]
      };
    });

    this.getParentChildMap(options, itemMap);
    const itemArray = Object.values(itemMap);

    itemArray.sort((a: any, b: any) => a.order - b.order);
    return itemArray ?? [];
  };

  getParentChildMap = (options: any[], itemMap) => {
    const itemsToDelete = [];

    options.forEach((item) => {
      let optionLowercase = this.lowercaseKeys(item);
      if (optionLowercase[this.dataParentIdField] && itemMap[optionLowercase[this.dataParentIdField]]) {
        const parentId = optionLowercase[this.dataParentIdField];
        const childId = optionLowercase[this.dataIdField];

        if (parentId !== childId) {
          if (!itemMap[parentId].children || itemMap[parentId].children.length === 0) {
            itemMap[parentId].children = [];
          }

          const childItem = itemMap[childId];
          if (childItem) {
            childItem.order = optionLowercase[this.dataOrderField];
            itemMap[parentId].children.push(childItem);

            this.setOrderForChildren(childItem, childItem.order);

            itemsToDelete.push(childId);
          }
        }
      }
    });

    itemsToDelete.forEach((childId) => {
      delete itemMap[childId];
    });

    return itemMap;
  };

  setOrderForChildren = (parentItem, order) => {
    if (parentItem.children) {
      parentItem.children.forEach((child) => {
        child.order = order;
        this.setOrderForChildren(child, child.order);
      });
    }
  };

  getSiderTriggerStyles = () => {
    const menuTitleColor = this.props.collapsedButtonColor;
    const triggerStyles: any = {};

    triggerStyles.backgroundColor = menuTitleColor;
    triggerStyles.height = "36px";
    triggerStyles.display = "flex";
    triggerStyles.alignItems = "center";
    triggerStyles.justifyContent = "center";
    triggerStyles.cursor = "pointer";
    triggerStyles.width = `${this.props.style?.width ?? "auto"} !important`;
    return triggerStyles;
  };

  renderMenu = () => {
    return (
      <Menu
        className={this.getClassName()}
        {...this.getProps()}
        onSelect={this.handleSelectMenuItem}
        selectedKeys={[this.getSelectedKey()]}
        defaultSelectedKeys={[this.getSelectedKey()]}
        defaultOpenKeys={[this.getParentOfSelectedKey()]}
        // openKeys={[this.getParentOfSelectedKey()]}
        items={this.getItems()}
      />
    );
  };

  render(): ReactNode {
    return (
      <>
        {this.props.mode !== "horizontal" ? (
          <Sider collapsed={this.state.collapsed} onCollapse={(e) => this.handleCollapseChange(e)} trigger={null}>
            {this.renderMenu()}
            {this.props.collapsible && (
              <div
                className="ant-layout-sider-trigger"
                style={{ ...this.getSiderTriggerStyles() }}
                onClick={() => this.handleCollapseChange(!this.state.collapsed)}
              >
                {this.props.value?.collapsed || this.props.collapsed ? (
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="#fff"
                    aria-hidden="true"
                  >
                    <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                  </svg>
                ) : (
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="#fff"
                    aria-hidden="true"
                  >
                    <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
                  </svg>
                )}
              </div>
            )}
          </Sider>
        ) : (
          this.renderMenu()
        )}
      </>
    );
  }
}

const siderMenu = withCommonEvents(SiderMenu);
export { siderMenu as SiderMenu };
