import { Col, Empty, FormInstance, Row, Table } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { ColumnType } from "antd/lib/table";
import { Guid } from "guid-typescript";
import _ from "lodash";
import moment from "moment";
import React, { Component, ReactNode } from "react";
import ReactDOM from "react-dom";
import isEqual from "react-fast-compare";
import shallowequal from "shallowequal";
import EmptyDataSvg from "../../../../public/images/empty-table.svg";
import { KMainFunctions } from "../../../shared/utilty/main-functions";
import { CommonProps } from "../common/common-props";
import { DashboardState, toCamelCase } from "../kuika-cl-model-runtimes";
import { CarouselBodyCell } from "./body/carousel/carousel-body-cell";
import { CarouselBodyWrapper, CarouselBodyWrapperProps } from "./body/carousel/carousel-body-wrapper";
import { CarouselItem } from "./body/carousel/carousel-item";
import { FlexGridBodyCell } from "./body/flex-grid/flex-grid-body-cell";
import { FlexGridBodyWrapper } from "./body/flex-grid/flex-grid-body-wrapper";
import { FlexGridItem } from "./body/flex-grid/flex-grid-item";
import { GalleryBodyCell } from "./body/gallery/gallery-body-cell";
import { GalleryBodyWrapper, GalleryBodyWrapperProps } from "./body/gallery/gallery-body-wrapper";
import { GalleryItem, GalleryItemProps } from "./body/gallery/gallery-item";
import { GoogleMapsBodyCell } from "./body/google-maps/google-maps-body-cell";
import { GoogleMapsBodyWrapper, GoogleMapsBodyWrapperProps } from "./body/google-maps/google-maps-body-wrapper";
import { GoogleMapsItem, ICoords, MarkerDetailPosition, MarkerDetailView } from "./body/google-maps/google-maps-item";
import { TableBodyCell } from "./body/table/table-body-cell";
import { TableBodyWrapper } from "./body/table/table-body-wrapper";
import { TableColumnProps } from "./body/table/table-column";
import { TableData, TableDataProps } from "./data/table-data";
import { TableHeaderCell } from "./header/table-header-cell";
import { TableHeaderRow } from "./header/table-header-row";
import { RefreshDelegate } from "./refresh-delegate";
import "./table.scss";
import { CarouselViewWrapper } from "./wrapper/carousel-view-wrapper";
import { FlexGridViewWrapper } from "./wrapper/flex-grid-view-wrapper";
import { GalleryViewWrapper } from "./wrapper/gallery-view-wrapper";
import { GoogleMapsViewWrapper } from "./wrapper/google-maps-view-wrapper";
import { TableViewWrapper } from "./wrapper/table-view-wrapper";

export type ViewMode = "table" | "gallery" | "carousel" | "flex-grid" | "google-maps";

declare let window: any;

export interface ITableProps {
  children?: any;
  nodatafoundmessage?: string;
  rowBgColor?: (rowData: any, rowIndex?: number) => string;
  rowFontColor?: (rowData: any, rowIndex?: number) => string;
  dataSource?: any[];
  loading?: boolean;
  size?: SizeType;
  sorter?: boolean;
  pagination?: boolean;
  showHeader?: boolean;
  style?: any;
  mode?: ViewMode;
  striped?: boolean;
  stripedColor?: string;
  // Maps Props
  onChange?: (value?: any | undefined) => void;
  value?: any;
  latitude?: string;
  longitude?: string;
  zoom?: number; /// zoom /// 1-> World /// 5-> Landmass/continent 10 -> City /// 15-> Streets /// 20-> Buildings şeklinde tanımlanırsa veya açıklama yazılırsa low code için daha anlamlı olabilir.
  isMarkerPicker?: boolean;
  isPicker?: boolean;
  hasSearchBox?: boolean;
  disableDoubleClickZoom?: boolean;
  fullscreenControl?: boolean;
  streetViewControl?: boolean;
  scaleControl?: boolean;
  zoomControl?: boolean;
  panControl?: boolean;
  rotateControl?: boolean;
  mapTypeControl?: boolean;
  autoCenter?: boolean;
  designClicked?: boolean;
  onClickDesign?: (value: boolean) => void;
  dataLatField?: string;
  dataLngField?: string;
  markerDetailView?: MarkerDetailView;
  markerDetailPosition?: MarkerDetailPosition;
  markerDetailHeight?: number;
  markerDetailWidth?: number;
  circleRadius?: number;
  groupPickerIcon?: string;
  groupPickerColor?: string;
  selectedLocationIcon?: string;
  selectedLocationColor?: string;
  currentLocationIcon?: string;
  currentLocationColor?: string;
  // Carousel Props
  setCurrentSlideNumber?: (val: number) => void;
  startingIndex?: number;
  hideTableOnEmpty?: boolean;
  rowHoverFontColor?: string;
  rowHoverBgColor?: string;
  kuikacomponentname?: string;
  onRowEditFinished?: () => void;
  form?: FormInstance<any>;
  insertRowActive?: boolean;
}

export interface ITableState {
  columnTitles?: string[];
  selectedRowKeys?: string[];
  columns?: TableColumnProps & CommonProps[];
  headerStyle?: any;
  rowStyle?: any;
  footerStyle?: any;
  headerRenderer?: any;
  rowRenderer?: any;
  selectedIndexGuid: string;
  selectedColumnIndexGuid: string;
  selectedRowDataGuid: string;
  // Maps States:
  maps?: any;
  map?: any;
  isDataMarkerSelected: boolean;
  value?: any;
  uniqueKey?: Guid;
  activeEditableRowIndex?: number;
  randomGuid?: string;
}

export abstract class BaseTable<P, S> extends Component<ITableProps & P & CommonProps, ITableState & S> {
  private memoizedDynamicCssResult = "";

  carouselCurrentSlideIdx: number = 0;

  timeOutId = undefined;

  lastSelectedLat: any;

  lastSelectedLng: any;

  constructor(props: ITableProps & P) {
    super(props);
    this.state = {
      columns: [],
      selectedRowKeys: [],
      selectedIndexGuid: Guid.create().toString(),
      selectedColumnIndexGuid: Guid.create().toString(),
      selectedRowDataGuid: Guid.create().toString(),
      uniqueKey: Guid.create(),
      activeEditableRowIndex: window.kuika.isDesignTime ? 0 : -1,
      randomGuid: Guid.create().toString()
    } as any;
  }

  public static defaultProps = {
    mode: "table",
    designClicked: true
  };

  static withColumnProps(props: any, value: any, rowData: any, rowIndex: number): any {
    props.value = value;
    props.rowData = rowData;
    props.rowIndex = rowIndex;
    return props;
  }

  static getDerivedStateFromProps(nextProps: ITableProps & CommonProps, prevState: ITableState) {
    const result: ITableState = {
      selectedIndexGuid: prevState.selectedIndexGuid,
      selectedColumnIndexGuid: prevState.selectedColumnIndexGuid,
      selectedRowDataGuid: prevState.selectedRowDataGuid,
      maps: prevState.maps,
      map: prevState.map,
      isDataMarkerSelected: false,
      activeEditableRowIndex: prevState.activeEditableRowIndex
    };
    if (nextProps.children) {
      result.columns = [];
      if (nextProps.mode === "carousel") {
        result.rowStyle = nextProps.style;
        BaseTable.setCarouselItem(result, nextProps.children);
      } else if (nextProps.mode === "gallery") {
        result.rowStyle = nextProps.style;
        BaseTable.setGalleryItem(result, nextProps.children);
      } else if (nextProps.mode === "flex-grid") {
        result.rowStyle = nextProps.style;
        BaseTable.setFlexGridItem(result, nextProps.children);
      } else if (nextProps.mode === "google-maps") {
        if (nextProps.value !== undefined) {
          result.value = nextProps.value;
        }
        React.Children.forEach(nextProps.children, (child, childIndex) => {
          switch (childIndex) {
            // GoogleMapsMarker
            case 0:
              BaseTable.setGoogleMapsItem(result, child, nextProps);
              break;
          }
        });
      } else {
        React.Children.forEach(nextProps.children, (child, childIndex) => {
          switch (childIndex) {
            // Header
            case 0:
              if (
                child.type.name == "TableHeader" ||
                (child.props.kuikacomponentname && child.props.kuikacomponentname === "TableHeader") ||
                child.type.name == "ReportTableHeader" ||
                (child.props.kuikacomponentname && child.props.kuikacomponentname === "ReportTableHeader")
              ) {
                BaseTable.setHeader(result, child);
              } else if (
                child.type.name == "TableRow" ||
                (child.props.kuikacomponentname && child.props.kuikacomponentname === "TableRow") ||
                child.type.name == "ReportTableRow" ||
                (child.props.kuikacomponentname && child.props.kuikacomponentname === "ReportTableRow")
              ) {
                BaseTable.setRow(result, child, nextProps, nextProps.sorter);
                result.headerRenderer = (_props: any) => {
                  return React.createElement("div");
                };
              }
              break;
            // Row
            default:
              BaseTable.setRow(result, child, nextProps, nextProps.sorter);
              break;
          }
        });
      }
      return result;
    }
  }

  static setHeader = (result: ITableState, child: any) => {
    let headerStyle: any = {};
    if (child.props.style) {
      headerStyle = child.props.style;
    }
    if (child.props.writingMode && child.props.writingMode === "vertical") {
      headerStyle.writingMode = "vertical-lr";
    }
    if (
      child.props.textDirection &&
      (child.props.textDirection === "Rotate Up" || child.props.textDirection === "Rotate Down")
    ) {
      headerStyle.writingMode = "vertical-lr";
      if (child.props.textDirection === "Rotate Up") {
        headerStyle.transform = "rotate(180deg)";
      }
    }
    result.headerStyle = headerStyle;
    result.headerRenderer = (props: any) => {
      return React.cloneElement(child, props);
    };
    if (child.props.columnTitles) {
      result.columnTitles = child.props.columnTitles.split(";");
    }
  };

  static setRow = (result: ITableState, child: any, _nextProps: ITableProps, sorter?: boolean) => {
    result.rowStyle = child.props.style;
    result.rowRenderer = (props: any) => {
      return React.cloneElement(child, props);
    };
    React.Children.forEach(child.props.children, (grandChild, grandChildIndex) => {
      const columnProps: TableColumnProps & CommonProps = _.clone(grandChild.props);
      const props = {};
      Object.keys(grandChild.props).forEach((key) => {
        if (key !== "children" || typeof grandChild.props[key] === "string") {
          props[key] = grandChild.props[key];
        }
      });
      columnProps.render = (value: any, rowData: any, rowIndex: number) => {
        let children = grandChild?.props?.children;
        if (grandChild?.props?.children?.length === 2 && grandChild.props.isEditableColumn) {
          children = [];
          if (grandChild.props.isEditableColumn) {
            if (result.activeEditableRowIndex === rowIndex) {
              children.push(grandChild.props.children[1]);
            } else {
              children.push(grandChild.props.children[0]);
            }
          }
        }
        return (
          <>{React.cloneElement(grandChild, BaseTable.withColumnProps(props, value, rowData, rowIndex), children)}</>
        );
      };
      delete columnProps.children;
      if (
        columnProps &&
        result &&
        result.columnTitles &&
        result.columnTitles.length > grandChildIndex &&
        result.columnTitles[grandChildIndex]?.length > 0
      ) {
        columnProps.title = result.columnTitles[grandChildIndex];
      }

      let value = grandChild.props?.children?.props?.value;
      if (value) {
        value = value.toString().replace("[datafield:", "").replace("]", "");
        columnProps.sorter =
          sorter === true ? (a, b) => BaseTable.compare(columnProps.specialSortingFormat, value, a, b) : undefined;
      }

      result?.columns?.push(columnProps);
    });
  };

  static isNotNull = (value: any) => {
    if (value === undefined) return false;
    if (value === null) return false;
    return true;
  };

  static compare = (specialSortingFormat, value, a, b) => {
    let x = "";
    if (
      BaseTable.isNotNull(a) &&
      BaseTable.isNotNull(value) &&
      BaseTable.isNotNull(a[value]) &&
      BaseTable.isNotNull(a[value].toString)
    ) {
      x = a[value].toString();
    } else {
      x = a[value];
    }
    if (!BaseTable.isNotNull(x)) {
      x = "";
    }

    let y = "";
    if (
      BaseTable.isNotNull(b) &&
      BaseTable.isNotNull(value) &&
      BaseTable.isNotNull(b[value]) &&
      BaseTable.isNotNull(b[value].toString)
    ) {
      y = b[value].toString();
    } else {
      y = b[value];
    }
    if (!BaseTable.isNotNull(y)) {
      y = "";
    }

    if (specialSortingFormat !== undefined && specialSortingFormat !== "") {
      if (
        specialSortingFormat === "DD/MM/YYYY HH:mm" ||
        specialSortingFormat === "DD/MM/YYYY HH:mm:ss" ||
        specialSortingFormat === "DD/MM/YYYY" ||
        specialSortingFormat === "MM/DD/YYYY HH:mm" ||
        specialSortingFormat === "MM/DD/YYYY HH:mm:ss" ||
        specialSortingFormat === "MM/DD/YYYY" ||
        specialSortingFormat === "HH:mm:ss" ||
        specialSortingFormat === "HH:mm"
      ) {
        const xDate = moment(x, specialSortingFormat);
        const yDate = moment(y, specialSortingFormat);
        return xDate.diff(yDate);
      }
    }

    if (BaseTable.isNumber(x) === true && BaseTable.isNumber(y) === true) {
      return Number(x) - Number(y);
    }
    if (x.localeCompare) {
      return x.localeCompare(y);
    }
    return 0;
  };

  static isNumber(r) {
    if (Number.isNaN(Number.parseFloat(r))) {
      return false;
    }
    return true;
  }

  static setGalleryItem = (result: ITableState, children: any) => {
    result.rowRenderer = (props: any) => {
      return <GalleryItem {...props}></GalleryItem>;
    };
    const columnProps: TableDataProps & CommonProps = {};
    columnProps.render = (_value: any, rowData: any, rowIndex: number) => {
      return (
        <TableData rowIndex={rowIndex} rowData={rowData}>
          {children}
        </TableData>
      );
    };
    result?.columns?.push(columnProps);
  };

  static setCarouselItem = (result: ITableState, children: any) => {
    result.rowRenderer = (props: any) => {
      return <CarouselItem {...props}></CarouselItem>;
    };
    const columnProps: TableDataProps & CommonProps = {};

    columnProps.render = (_value: any, rowData: any, rowIndex: number) => (
      <TableData rowIndex={rowIndex} rowData={rowData}>
        {children}
      </TableData>
    );
    result?.columns?.push(columnProps);
  };

  static setGoogleMapsItem = (result: ITableState, children: any, nextProps: ITableProps) => {
    let rowPopoverRenderer: any | undefined;

    React.Children.forEach(nextProps.children, (child, childIndex) => {
      switch (childIndex) {
        // GoogleMapsMarkerPopup
        case 1:
          rowPopoverRenderer = (_value: any, rowData: any, rowIndex: number) => {
            return (
              <TableData
                rowIndex={rowIndex}
                rowData={rowData}
                markerDetailHeight={nextProps.markerDetailHeight}
                markerDetailWidth={nextProps.markerDetailWidth}
              >
                {React.cloneElement(
                  child,
                  {
                    ...child.props,
                    markerDetailWidth: nextProps.markerDetailWidth,
                    markerDetailHeight: nextProps.markerDetailHeight
                  },
                  child.props.children
                )}
              </TableData>
            );
          };
      }
    });

    result.rowRenderer = (props: any) => {
      return <GoogleMapsItem {...props} rowPopoverRenderer={rowPopoverRenderer}></GoogleMapsItem>;
    };
    const columnProps: TableDataProps & CommonProps = {};

    columnProps.render = (_value: any, rowData: any, rowIndex: number) => (
      <TableData rowIndex={rowIndex} rowData={rowData}>
        {children}
      </TableData>
    );
    result?.columns?.push(columnProps);
  };

  static refreshGoogleMapsItem: ((nextProps?: any, callBack?: any) => void) | undefined;

  static setFlexGridItem = (result: ITableState, children: any) => {
    result.rowRenderer = (props: any) => {
      return <FlexGridItem {...props}></FlexGridItem>;
    };
    const columnProps: TableDataProps & CommonProps = {};

    columnProps.render = (_value: any, rowData: any, rowIndex: number) => (
      <TableData rowIndex={rowIndex} rowData={rowData}>
        {children}
      </TableData>
    );
    result?.columns?.push(columnProps);
  };

  setStyle = (_props?: ITableProps) => {
    const node: Element | null | Text = ReactDOM.findDOMNode(this);
    if (!node) {
      return;
    }
    const table = (node as Element).querySelectorAll("div > div > div > div > div > table");
    this.setBackgroundColor(table);
    const tbody = (node as Element).querySelectorAll("div > div > div > div > div > tbody");
    this.setBackgroundColor(tbody);
    const tdList = (node as Element).querySelectorAll("div > div > div > div > div > table > tbody > tr > td");
    tdList.forEach((td: any, index: number) => {
      this.setBackgroundColor(td);
      if (this.props.mode === "table") {
        this.setAlignments(td, index);
      }
    });
    if (this.props.mode === "table") {
      this.setDynamicStyle();
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    const { maxHeight, boxShadow, height, minHeight, padding, paddingRight, paddingLeft, paddingTop, paddingBottom } =
      this.props.style;
    if (maxHeight || height || minHeight) {
      if (height) {
        result += `.${className.trim()} .ant-table {
          height: ${height} !important;
        }`;
        result += `.${className.trim()} .ant-table-content table {
          min-height: ${height} !important;
        }`;
      }
      if (minHeight) {
        result += `.${className.trim()} .ant-table {
          min-height: ${minHeight} !important;
        }`;
      }
      if (maxHeight) {
        result += `.${className.trim()} .ant-table {
            max-height: ${maxHeight} !important;
            overflow-y: scroll !important;
          }`;
      }
    }

    if (boxShadow) {
      result += `.${className.trim()} {
          box-shadow: ${boxShadow} !important;
        }`;
    }

    if (this.props?.striped) {
      result += `.${className.trim()} table tr:nth-child(2n) td {
            background-color: #F5F7FA;
        }`;
    }

    if (this.props?.striped && this.props?.stripedColor) {
      result += `.${className.trim()} table tr:nth-child(2n) td {
            background-color: ${this.props?.stripedColor};
        }`;
    }

    if (
      (padding || paddingRight || paddingLeft || paddingTop || paddingBottom) &&
      window?.kuika?.dashboardState === DashboardState.reportDesigner
    ) {
      result += `.${className.trim()} .ant-table {
          padding: ${paddingTop ?? 0}px ${paddingRight ?? 0}px ${paddingBottom ?? 0}px ${paddingLeft ?? 0}px !important;
        }`;
    }

    result += `.${className.trim()} .ant-table-wrapper {
      overflow: auto !important;
    }`;
    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getClassName = () => {
    let result = "";
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} ktable_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  hideNoDataIfNotNeeded = (props: any) => {
    if (!props.nodatafoundmessage) {
      const node = document.getElementById(props.id);
      if (node) {
        const empty: HTMLCollectionOf<Element> = node.getElementsByClassName("ant-empty");
        if (empty.length > 0) {
          (empty[0] as HTMLElement).style.display = "none";
        }
      }
    }
  };

  setBackgroundColor = (element: any, props?: any) => {
    if (props && props.style && props.style.backgroundColor) {
      element.style["background-color"] = props.style.backgroundColor;
    }
  };

  setAlignments = (element: any, index: number) => {
    const isDesignTime = window.kuika?.isDesignTime;
    let columnStyles: React.CSSProperties = {};
    if (this.props?.children[1]?.props?.children?.length > 0) {
      const columnIndex = index % this.props?.children[1]?.props?.children.length;
      columnStyles = this.props?.children[1]?.props?.children[columnIndex]?.props?.style;
    }
    let node: any = element.firstChild;
    if (!node) {
      return;
    }
    if (!isDesignTime) {
      node = element;
    }
    KMainFunctions.handleAlignments(node, columnStyles);
    if (!isDesignTime) {
      node.style.display = "table-cell";
      node.style.verticalAlign =
        columnStyles.alignItems === "center"
          ? "middle"
          : columnStyles.alignItems === "flex-end"
          ? "bottom"
          : columnStyles.alignItems === "flex-start"
          ? "top"
          : "center";
    }
  };

  componentDidUpdate = (prevProps: ITableProps) => {
    this.setStyle(prevProps);
    this.hideNoDataIfNotNeeded(prevProps);

    const isEql = isEqual(this.props.dataSource, prevProps.dataSource);
    if (this.state.activeEditableRowIndex != -1 && !isEql) {
      let formValues = {};
      let rowValue = this.getDataSource()[this.state.activeEditableRowIndex];
      if (rowValue) {
        Object.keys(rowValue).forEach((cellfieldName) => {
          let key = `Table_${this.props.id}_${cellfieldName}`;
          formValues[key] = rowValue[cellfieldName];
        });
        this.props.form?.setFieldsValue(formValues);
        this.setState({ randomGuid: Guid.create().toString() } as any, () => {
          this.setState({ activeEditableRowIndex: -1 } as any);
        });
      }
    }
  };

  handleEscKey = (event: any) => {
    if (event.key === "Escape") {
      if (this.state.activeEditableRowIndex != -1) {
        this.setState({ activeEditableRowIndex: -1 } as any);
      }
    }
    if (event.key === "Enter") {
      if (this.state.activeEditableRowIndex != -1 && this.props.onRowEditFinished) {
        this.handleOnRowEditFinished();
      }
    }
  };

  handleClick = (event: any) => {
    if (
      event.target.id.toString().includes("body") ||
      event.target.id.toString().includes("header") ||
      event.target.id.toString().includes("footer")
    )
      if (this.state.activeEditableRowIndex != -1 && this.props.onRowEditFinished) {
        this.handleOnRowEditFinished();
      }
  };

  componentWillUnmount(): void {
    document.removeEventListener("keydown", this.handleEscKey);
    document.removeEventListener("click", this.handleClick);
  }

  componentDidMount = () => {
    this.setStyle(this.props);
    this.hideNoDataIfNotNeeded(this.props);
    document.addEventListener("keydown", this.handleEscKey);
    document.addEventListener("click", this.handleClick);
  };

  getRowBgColor = (rowData: any, rowIndex?: number): string | undefined => {
    if (this.props.rowBgColor) {
      return this.props.rowBgColor(rowData, rowIndex);
    }
    return undefined;
  };

  getRowFontColor = (rowData: any, rowIndex?: number): string | undefined => {
    if (this.props.rowFontColor) {
      return this.props.rowFontColor(rowData, rowIndex);
    }
    return undefined;
  };

  getRowStyle = (rowValue: any, index: number | undefined): any | undefined => {
    let style: any | undefined = _.clone(this.state.rowStyle);
    if (!style) {
      style = {};
    }
    const color = this.getRowFontColor(rowValue, index);
    if (color) {
      style.color = color;
    }
    const backgroundColor = this.getRowBgColor(rowValue, index);
    if (backgroundColor) {
      style.backgroundColor = backgroundColor;
    }
    return style;
  };

  getViewWrapper = () => {
    switch (this.props.mode) {
      case "table":
        return (props) => <TableViewWrapper {...props} style={this.props.style}></TableViewWrapper>;
      case "carousel":
        return (props) => <CarouselViewWrapper {...props} style={this.props.style}></CarouselViewWrapper>;
      case "google-maps":
        return (props) => <GoogleMapsViewWrapper {...props} style={this.props.style}></GoogleMapsViewWrapper>;
      case "gallery":
        return (props) => <GalleryViewWrapper {...props} style={this.props.style}></GalleryViewWrapper>;
      case "flex-grid":
        return (props) => <FlexGridViewWrapper {...props} style={this.props.style} />;
    }
  };

  refreshGoogleMapsBodyWrapper: ((nextProps?: any, callBack?: any) => void) | undefined;

  getBodyWrapper = () => {
    switch (this.props.mode) {
      case "table":
        return TableBodyWrapper;
      case "carousel":
        return (props) => (
          <CarouselBodyWrapper
            {...props}
            {...this.getCarouselBodyWrapperProps()}
            setCurrentSlideNumber={(val: number) => (this.carouselCurrentSlideIdx = val)}
          />
        );
      case "google-maps":
        return (props) => (
          <RefreshDelegate
            {...props}
            setRefreshDelegate={(refreshMethod) => {
              this.refreshGoogleMapsBodyWrapper = refreshMethod;
            }}
            {...this.getGoogleMapsBodyWrapperProps()}
            Component={GoogleMapsBodyWrapper}
          />
        );
      case "gallery":
        return (props) => <GalleryBodyWrapper {...props} {...this.getGalleryBodyWrapperProps()}></GalleryBodyWrapper>;
      case "flex-grid":
        return (props) => <FlexGridBodyWrapper {...props} {...this.getGalleryBodyWrapperProps()} />;
    }
  };

  getBodyCell = () => {
    switch (this.props.mode) {
      case "table":
        return TableBodyCell;
      case "carousel":
        return CarouselBodyCell;
      case "google-maps":
        return GoogleMapsBodyCell;
      case "gallery":
        return GalleryBodyCell;
      case "flex-grid":
        return FlexGridBodyCell;
    }
  };

  getCarouselBodyWrapperProps = () => {
    const result: CarouselBodyWrapperProps = {};
    const p = this.props as CarouselBodyWrapperProps;
    if (p.autoplaySpeed) result.autoplaySpeed = p.autoplaySpeed;
    if (p.autoplay) result.autoplay = p.autoplay;
    if (p.fade) result.fade = p.fade;
    if (p.pauseOnDotsHover) result.pauseOnDotsHover = p.pauseOnDotsHover;
    if (p.slidesToScroll) result.slidesToScroll = p.slidesToScroll;
    if (p.dots === false) {
      result.dots = false;
    }
    if (p.dots === undefined || p.dots === true) {
      result.dots = true;
    }
    if (p.slidesToShow) result.slidesToShow = p.slidesToShow;
    if (p.speed) result.speed = p.speed;
    if (p.swipe) result.swipe = p.swipe;
    if (p.vertical) result.vertical = p.vertical;
    if (p.onChange) result.onChange = p.onChange;
    if (p.startingIndex && p.startingIndex > 0) {
      result.startingIndex = p.startingIndex;
      this.carouselCurrentSlideIdx = p.startingIndex;
    }
    if (p.hoverBgColor) result.hoverBgColor = p.hoverBgColor;
    if (p.hoverFontColor) result.hoverFontColor = p.hoverFontColor;
    return result;
  };

  getLatValue = () => {
    if (this.lastSelectedLat !== undefined) {
      return this.lastSelectedLat;
    }
    return "";
  };

  getLngValue = () => {
    if (this.lastSelectedLng !== undefined) {
      return this.lastSelectedLng;
    }
    return "";
  };

  getGoogleMapsBodyWrapperProps = () => {
    const result: GoogleMapsBodyWrapperProps = {} as any;
    const p = this.props;
    result.value = this.state.value;
    if (p.style) result.style = p.style;
    if (p.latitude) result.latitude = p.latitude;
    if (p.longitude) result.longitude = p.longitude;
    if (p.zoom) result.zoom = p.zoom;
    if (p.dataLatField) result.dataLatField = p.dataLatField;
    if (p.dataLngField) result.dataLngField = p.dataLngField;
    if (p.markerDetailView) result.markerDetailView = p.markerDetailView;
    if (p.markerDetailPosition) result.markerDetailPosition = p.markerDetailPosition;
    if (p.markerDetailHeight) result.markerDetailHeight = p.markerDetailHeight;
    if (p.markerDetailWidth) result.markerDetailWidth = p.markerDetailWidth;
    if (p.designClicked) result.designClicked = p.designClicked;
    result.isPicker = p.isPicker;
    result.isMarkerPicker = p.isMarkerPicker;
    result.hasSearchBox = p.hasSearchBox;
    result.disableDoubleClickZoom = p.disableDoubleClickZoom;
    result.fullscreenControl = p.fullscreenControl;
    result.streetViewControl = p.streetViewControl;
    result.scaleControl = p.scaleControl;
    result.zoomControl = p.zoomControl;
    result.panControl = p.panControl;
    result.rotateControl = p.rotateControl;
    result.mapTypeControl = p.mapTypeControl;
    result.autoCenter = p.autoCenter;
    result.circleRadius = p.circleRadius;
    result.clearActiveContent = () => {
      if (this.refreshGoogleMapsBodyWrapper) {
        this.refreshGoogleMapsBodyWrapper({ activeContent: undefined, designClicked: false });
      }
    };
    result.onChange = (isDataMarkerSelected: boolean, value?: ICoords) => {
      this.setState({ value, isDataMarkerSelected } as any, () => {
        if (this.refreshGoogleMapsBodyWrapper) {
          let wrapperProps: any = { value, isDataMarkerSelected };
          if (isDataMarkerSelected !== true) {
            wrapperProps = { value, isDataMarkerSelected, activeContent: undefined };
          }
          this.refreshGoogleMapsBodyWrapper(wrapperProps, () => {
            if (p.onChange) {
              this.lastSelectedLat = value?.latitude;
              this.lastSelectedLng = value?.longitude;
              p.onChange(value);
            }
          });
        }
      });
    };
    result.onGoogleApiLoaded = (map: any, maps: any) => {
      this.setState({ map, maps }, () => {
        if (this.refreshGoogleMapsBodyWrapper) {
          this.refreshGoogleMapsBodyWrapper({ map, maps }, () => {});
        }
      });
    };
    result.maps = this.state.maps;
    result.map = this.state.map;
    result.isDataMarkerSelected = this.state.isDataMarkerSelected;
    result.groupPickerIcon = p.groupPickerIcon;
    result.groupPickerColor = p.groupPickerColor;
    result.currentLocationIcon = p.currentLocationIcon;
    result.currentLocationColor = p.currentLocationColor;
    return result;
  };

  getGalleryBodyWrapperProps = () => {
    const result: GalleryBodyWrapperProps = {};
    const p = this.props as GalleryBodyWrapperProps;
    if (p.horizontalGutter) result.horizontalGutter = p.horizontalGutter;
    if (p.verticalGutter) result.verticalGutter = p.verticalGutter;
    return result;
  };

  public getSelectedIndex = () => {
    const selectedIndexHiddenInput = this.getSelectedRowIndexHiddenInput();
    if (selectedIndexHiddenInput) {
      return selectedIndexHiddenInput.value;
    }
  };

  getSelectedColumnIndex = () => {
    const selectedIndexHiddenInput = this.getSelectedColumnIndexHiddenInput();
    if (selectedIndexHiddenInput) {
      return selectedIndexHiddenInput.value;
    }
  };

  public getSelectedRowData = () => {
    const selectedRowDataHiddenInput = this.getSelectedRowDataHiddenInput();
    if (!this.props.dataSource || !selectedRowDataHiddenInput || !selectedRowDataHiddenInput.value) {
      return;
    }
    return JSON.parse(selectedRowDataHiddenInput.value);
  };

  public getCarouselCurrentData = () => {
    if (this.props.dataSource && this.props.mode === "carousel") {
      return this.props.dataSource[this.carouselCurrentSlideIdx];
    }
    return {} as any;
  };

  getSelectedRowIndexHiddenInput = () => {
    const selectedIndexHiddenInput = window.document.getElementById(this.state.selectedIndexGuid);
    return selectedIndexHiddenInput;
  };

  getSelectedColumnIndexHiddenInput = () => {
    const selectedColumnIndexHiddenInput = window.document.getElementById(this.state.selectedColumnIndexGuid);
    return selectedColumnIndexHiddenInput;
  };

  getSelectedRowDataHiddenInput = () => {
    const selectedColumnIndexHiddenInput = window.document.getElementById(this.state.selectedRowDataGuid);
    return selectedColumnIndexHiddenInput;
  };

  handleOnRowEditFinished = () => {
    let oldValues = {};
    let newValues = {};
    let rowValue = this.getDataSource()[this.state.activeEditableRowIndex];
    if (rowValue) {
      Object.keys(rowValue).forEach((cellfieldName) => {
        let key = `Table_${this.props.id}_${cellfieldName}`;
        oldValues[key] = rowValue[cellfieldName];
        newValues[key] = this.props.form.getFieldValue(key);
      });
      const isEql = isEqual(oldValues, newValues);
      if (isEql) {
        this.setState({ activeEditableRowIndex: -1 } as any);
      } else {
        this.props.onRowEditFinished();
      }
    }
  };

  handleRowClick = (_e: any, rowValue: any, rowIndex?: number) => {
    if (
      this.props.onRowEditFinished &&
      this.state.activeEditableRowIndex != -1 &&
      rowIndex != this.state.activeEditableRowIndex
    ) {
      this.handleOnRowEditFinished();
    }
    const selectedIndexHiddenInput = this.getSelectedRowIndexHiddenInput();
    if (selectedIndexHiddenInput && rowIndex !== undefined) {
      selectedIndexHiddenInput.value = rowIndex.toString();
    }
    const selectedRowDataHiddenInput = this.getSelectedRowDataHiddenInput();
    if (selectedRowDataHiddenInput && rowValue !== undefined) {
      selectedRowDataHiddenInput.value = JSON.stringify(rowValue);
    }
  };

  handleRowDoubleClick = (_e: any, rowValue: any, rowIndex?: number) => {
    if (this.state.activeEditableRowIndex != rowIndex) {
      let formValues = {};
      Object.keys(rowValue).forEach((cellfieldName) => {
        let key = `Table_${this.props.id}_${cellfieldName}`;
        formValues[key] = rowValue[cellfieldName];
      });
      this.props.form?.setFieldsValue(formValues);
      this.setState({ activeEditableRowIndex: rowIndex } as any, () => {});
    }
  };

  handleOnRowBlur = (_e: any, _rowValue: any, _rowIndex?: number) => {};

  handleCellClick = (columnIndex) => {
    const selectedColumnIndexHiddenInput = this.getSelectedColumnIndexHiddenInput();
    if (selectedColumnIndexHiddenInput && columnIndex !== undefined) {
      selectedColumnIndexHiddenInput.value = columnIndex.toString();
    }
  };

  getRowComponent = () => {
    const childList = React.Children.toArray(this.props.children);
    return childList[1];
  };

  getPagination = () => {
    if (this.props.pagination && this.props.pagination === true) {
      return {};
    }
    return false;
  };

  shouldComponentUpdate = (nextProps: any, nextState: any) => {
    if (nextProps.mode === "carousel") {
      if (this.props.dataSource === undefined || this.props.dataSource !== nextProps.dataSource) return true;
      return false;
    }
    if (nextProps.mode === "google-maps") {
      if (this.props.dataSource === undefined || this.props.dataSource !== nextProps.dataSource) {
        if (_.isEqual(this.props.dataSource, nextProps.dataSource) === false) {
          return true;
        }
      }
      if (this.refreshGoogleMapsBodyWrapper && this.state.value !== nextState.value) {
        const { value } = nextState;
        if (value != undefined && value.latitude != undefined && value.longitude != undefined) {
          if (this.props.autoCenter !== true) {
            this.refreshGoogleMapsBodyWrapper({
              value: nextState.value,
              latitude: value.latitude,
              longitude: value.longitude
            });
          } else {
            this.refreshGoogleMapsBodyWrapper({ value: nextState.value });
          }
        }

        this.refreshGoogleMapsBodyWrapper({ value: nextState.value });
      }
      return false;
    }
    return !shallowequal(nextProps, this.props) || !shallowequal(nextState, this.state);
  };

  getRowProps = () => {
    const result: any = {};
    if (this.props.mode === "gallery") {
      result.desktopColumnsCount = (this.props as GalleryItemProps).desktopColumnsCount;
      result.tabletColumnsCount = (this.props as GalleryItemProps).tabletColumnsCount;
      result.phoneColumnsCount = (this.props as GalleryItemProps).phoneColumnsCount;
    } else if (this.props.mode === "google-maps") {
      result.value = this.state.value;
      result.map = this.state.map;
      result.maps = this.state.maps;
      result.isDataMarkerSelected = this.state.isDataMarkerSelected;
    }
    return result;
  };

  convertToCoordsFieldsFromLatField = (record: any): number | undefined => {
    if (this.props.dataLatField === undefined) return undefined;
    if (this.props.dataLatField !== undefined && record[toCamelCase(this.props.dataLatField)] !== undefined) {
      const latitude = parseFloat(record[toCamelCase(this.props.dataLatField)]);
      return latitude;
    }
  };

  convertToCoordsFieldsFromLngField = (record: any): number | undefined => {
    if (this.props.dataLngField === undefined) return undefined;
    if (this.props.dataLngField !== undefined && record[toCamelCase(this.props.dataLngField)] !== undefined) {
      const longitude = parseFloat(record[toCamelCase(this.props.dataLngField)]);
      return longitude;
    }
  };

  getRowLat = (rowData: any) => {
    const latitude = this.convertToCoordsFieldsFromLatField(rowData);
    return latitude;
  };

  getRowLng = (rowData: any) => {
    const longitude = this.convertToCoordsFieldsFromLngField(rowData);
    return longitude;
  };

  resetEditableRow = () => {
    if (this.state.activeEditableRowIndex != -1 && this.props.onRowEditFinished) {
      this.handleOnRowEditFinished();
    }
  };

  getDataSource = () => {
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.props.insertRowActive && this.props.dataSource && !isDesignTime) {
      let mockDataSource = { id: this.state?.randomGuid };
      let children = this.props.children[1].props.children;
      children.forEach((element) => {
        if (
          element &&
          element.props &&
          element.props.children &&
          element.props.children[0] &&
          element.props.children[0].props &&
          element.props.children[0].props.value
        ) {
          var key = element.props.children[0].props.value as string;
          if (key) {
            key = key.replace("[datafield:", "").replace("]", "");
            mockDataSource[key] = undefined;
          }
        }
      });
      return [mockDataSource, ...this.props.dataSource];
    }
    return this.props.dataSource;
  };

  getTableStyles = () => {
    if (this.props.mode === "carousel") {
      const { width, height, maxWidth, maxHeight } = this.props.style;
      delete this.props.style?.width;
      delete this.props.style?.height;
      delete this.props.style?.maxWidth;
      delete this.props.style?.maxHeight;
      return {
        width,
        height,
        maxWidth,
        maxHeight
      };
    }
  };

  render = (): ReactNode => {
    const defaultEmptyProps = {
      xl: 24 / (this.props as GalleryItemProps).desktopColumnsCount,
      md: 24 / (this.props as GalleryItemProps).tabletColumnsCount,
      sm: 24 / (this.props as GalleryItemProps).phoneColumnsCount,
      xs: 24 / (this.props as GalleryItemProps).phoneColumnsCount,

      style: {
        padding: "5px 5px"
      }
    };
    let defaultTemp = [];

    if (this.props.kuikacomponentname == "GalleryView" && !this.props.children) {
      let a = Array(24).fill(1);
      a.forEach(() => {
        defaultTemp.push(
          <Col {...defaultEmptyProps}>
            <div className="kuika_emptygalleryviewPlaceholder" />
          </Col>
        );
      });

      return <Row>{defaultTemp}</Row>;
    }
    if (this.props.mode == "carousel" && !this.props.children) {
      return (
        <>
          <div style={{ display: "flex" }}>
            <div className="emptyCarouselLeft" />
            <div className="emptyCarouselMiddle" />
            <div className="emptyCarouselRight" />
          </div>
          <ul className="slick-dots" style={{ display: "block" }}>
            <li className="slick-active">
              <button>1</button>
            </li>
            <li className="">
              <button>2</button>
            </li>
            <li className="">
              <button>3</button>
            </li>
          </ul>
        </>
      );
    }
    if (
      this.props.hideTableOnEmpty == true &&
      (this.props.dataSource == undefined || this.props.dataSource.length < 1)
    ) {
      return <></>;
    }
    return (
      <>
        <div className={`kuika_table__wrapper${this.getClassName()}`}>
          <Table
            id={this.props.id}
            style={{ ...this.getTableStyles() }}
            showHeader={this.props.showHeader}
            pagination={this.getPagination()}
            dataSource={this.getDataSource()}
            components={{
              table: this.getViewWrapper(),
              header: {
                wrapper: this.state.headerRenderer,
                row: TableHeaderRow,
                cell: TableHeaderCell
              },
              body: {
                wrapper: this.getBodyWrapper(),
                row: this.state.rowRenderer,
                cell: this.getBodyCell()
              }
            }}
            locale={{
              emptyText: (
                <Empty
                  image={
                    <img
                      src={EmptyDataSvg}
                      style={{
                        width: "100%"
                      }}
                    />
                  }
                  description={this.props.nodatafoundmessage ? this.props.nodatafoundmessage : ""}
                />
              )
            }}
            onRow={(rowValue: any, rowIndex?: number) => ({
              ...this.getRowProps(),
              onMouseLeave: (e: any) => {
                this.handleOnRowBlur(e, rowValue, rowIndex);
              },
              onDoubleClick: (e: any) => {
                this.handleRowDoubleClick(e, rowValue, rowIndex);
              },
              onTouchStart: (e: any) => {
                this.timeOutId = setTimeout(() => {
                  this.handleRowDoubleClick(e, rowValue, rowIndex);
                }, 500);
              },
              onTouchEnd: (e: any) => {
                clearTimeout(this.timeOutId);
              },
              onClickCapture: (e: any) => {
                this.handleRowClick(e, rowValue, rowIndex);
              },
              editMode: this.state.activeEditableRowIndex == rowIndex,
              style: this.getRowStyle(rowValue, rowIndex),
              hoverFontColor: this.props.rowHoverFontColor,
              hoverBgColor: this.props.rowHoverBgColor,
              tableClassName: this.getClassName().trim(),
              showTableHeader: this.props.showHeader,
              desktopColumnsCount: (this.props as GalleryItemProps).desktopColumnsCount,
              tabletColumnsCount: (this.props as GalleryItemProps).tabletColumnsCount,
              phoneColumnsCount: (this.props as GalleryItemProps).phoneColumnsCount,
              mode: this.props.mode,
              rowData: rowValue,
              rowIndex,
              onClick: () => this.resetEditableRow,
              rowComponent: this.getRowComponent(),
              lat: this.getRowLat(rowValue),
              lng: this.getRowLng(rowValue),
              dataLatField: this.props?.dataLatField,
              dataLngField: this.props?.dataLngField,
              marker: { lat: this.getRowLat(rowValue), lng: this.getRowLng(rowValue) },
              rowStyle: this.getRowStyle(rowValue, rowIndex),
              value: this.state.value,
              isDataMarkerSelected: this.state.isDataMarkerSelected,
              onClickDesign: (designClicked: boolean) => {
                if (this.props.onClickDesign) {
                  this.props.onClickDesign(designClicked);
                }
              },
              openDrawer: (content?: any) => {
                if (content !== undefined) {
                  if (this.refreshGoogleMapsBodyWrapper) {
                    this.refreshGoogleMapsBodyWrapper({ activeContent: content });
                  }
                }
              },
              designClicked: (this.props.designClicked as any) === "" ? true : this.props.designClicked,
              markerDetailView: this.props.markerDetailView,
              position: this.props.markerDetailPosition,
              height: this.props.markerDetailHeight,
              width: this.props.markerDetailWidth,
              selectedLocationIcon: this.props.selectedLocationIcon,
              selectedLocationColor: this.props.selectedLocationColor
            })}
            onHeaderRow={() => ({
              style: this.state.headerStyle,
              mode: this.props.mode,
              onClick: this.resetEditableRow
            })}
            columns={this.state.columns
              ?.filter((c) => c.visibility !== "hidden")
              .map((column: any, columnIndex: number) => {
                const { columns } = this.state;
                const c: ColumnType<any> = {
                  ...column,
                  onCell: (rowData: any, rowIndex?: number): any => ({
                    onCellClick: (_e: any) => {
                      this.handleCellClick(columnIndex);
                    },
                    style: column.style,
                    calculatedCellBgColor: column.calculatedCellBgColor,
                    calculatedCellFontColor: column.calculatedCellFontColor,
                    alwaysVisibleOnMobileResolution: column.alwaysVisibleOnMobileResolution,
                    rowIndex,
                    rowData,
                    columnIndex,
                    title: column.title,
                    columns: columns?.filter((x: any) => x.visible === true),
                    mode: this.props.mode,
                    isEditable: column.isEditableColumn,
                    isEditMode: column.isEditableColumn && this.state.activeEditableRowIndex == rowIndex
                  }),
                  onHeaderCell: (): any => ({
                    style: this.state.headerStyle,
                    columnStyle: column.style,
                    title: column.title,
                    mode: this.props.mode,
                    textDirection: this.props.children[0]?.props?.textDirection
                  })
                };
                return c;
              })}
            loading={this.props.loading}
            size={this.props.size}
            bordered={false}
          />

          <input type="hidden" id={this.state.selectedIndexGuid}></input>
          <input type="hidden" id={this.state.selectedColumnIndexGuid}></input>
          <input type="hidden" id={this.state.selectedRowDataGuid}></input>
        </div>
      </>
    );
  };
}
